import React, { useContext, useEffect, useState } from 'react';
import Parques from '../../content/imgs/menu/parques.png';
import Procedimentos from '../../content/imgs/menu/procedimentos.png';
import Bin from '../../content/imgs/icons/bin.png';
import { useNavigate } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Dialog from '../components/alertDialog';
// import Premios from '../../content/imgs/menu/premios.png';
import Loading from '../components/loading';
import { ClientsContext } from '../../contexts/Context';

export default function Settings() {
    const navigate = useNavigate();
    const context = useContext(ClientsContext);

    const [toggle, setToggle] = useState(0);
    const [newEle, setNewEle] = useState('');
    const [listas, setListas] = useState<any>(null);
    const toggleList = ['left-0', 'left-1/3', 'left-2/3'];

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                let lista1, lista2, lista3;
                lista1 = context?.settings?.tipoDePagamento?.all;
                lista2 = context?.settings?.tipoDeOcorrencia?.all;
                lista3 = context?.settings?.tipoDeCancelamento?.all;
                setListas([lista1, lista2, lista3]);
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        if (context?.settings)
            fetchData();
    }, [context?.settings]);

    useEffect(() => {
        setNewEle('');
    }, [toggle]);


    function Button({ image, text, href }: { image: any, text: string, href: string }) {
        return (
            <div onClick={() => navigate('/settings' + href)} className='bg-[var(--primary)] rounded-[15px] w-[8rem] quatro:w-[9rem] h-[7rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>
                <img src={image} alt="button" className='h-[5rem]' />
                <p className='text-white uppercase quatro:text-[.9rem] text-[.8rem]'>{text}</p>
            </div>
        )
    }

    async function remove(ele: string, index: number) {
        if (!window.confirm(`Tem a certeza que quer apagar o tipo '${ele}'?`)) {
            return;
        }
        let newListas = [...listas];
        newListas[toggle].splice(index, 1);
        // Remove from firebase
        try {
            await updateDoc(doc(db, 'settings', toggle === 0 ? 'tipoDePagamento' : toggle === 1 ? 'tipoDeOcorrencia' : 'tipoDeCancelamento'), { all: newListas[toggle] })
                .then(() => {
                    openDialog(`Tipo '${ele}' removido.`, () => null);
                    setListas(newListas);
                })
        } catch (error) {
            console.error("Error updating document: ", error);
            openDialog(`Erro ao remover ${ele}.`, () => {});
        }
    }

    async function add() {
        if (newEle === '') {
            openDialog('Tipo inválido.', () => null)
            return;
        }
        let newListas = [...listas];
        newListas[toggle].push(newEle);
        // Add to firebase
        try {
            await updateDoc(doc(db, 'settings', toggle === 0 ? 'tipoDePagamento' : toggle === 1 ? 'tipoDeOcorrencia' : 'tipoDeCancelamento'), { all: newListas[toggle] })
                .then(() => {
                    openDialog(`Tipo '${newEle}' adicionado.`, () => null);
                    setListas(newListas);
                    setNewEle('');
                })
        } catch (error) {
            console.error("Error updating document: ", error);
            openDialog(`Erro ao adicionar '${newEle}'.`, () => {});
        }
    }

    if (!listas) {
        return (
            <Loading/>
        )
    }

    return (
        <div className='w-full pw-[8vw] flex justify-center'>
            <button onClick={() => navigate('/home')} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem] uppercase'>SETTINGS</h1>
                <div className='grid grid-cols-2 gap-4 quatro:gap-6'>
                    <Button image={Parques} text="Parques" href="/parques" />
                    <Button image={Procedimentos} text="Procedimentos" href="/procedimentos" />
                    <div onClick={() => navigate('/settings/marcas')} className='bg-[var(--primary)] rounded-[15px] w-[8rem] quatro:w-[9rem] h-[7rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black px-6 text-center'>
                        <p className='text-white uppercase quatro:text-[.9rem] text-[.8rem]'>Definições de Marcas</p>
                    </div>
                </div>
                <div className='cinco:px-[3rem] w-full flex flex-col gap-10'>
                    <div className='relative flex items-center w-full h-8 border-2 border-black rounded-[12px] justify-evenly'>
                        <div className={`absolute top-0 ${toggleList[toggle]} h-full w-1/3 bg-[var(--yellow)] rounded-[12px] z-[-1] transition-all duration-300`}></div>
                        <p onClick={() => setToggle(0)} className='w-1/3 text-[.8rem] text-center cursor-pointer'>Pagamento</p>
                        <p onClick={() => setToggle(1)} className='w-1/3 text-[.8rem] text-center cursor-pointer'>Ocorrência</p>
                        <p onClick={() => setToggle(2)} className='w-1/3 text-[.8rem] text-center cursor-pointer'>Cancelar</p>
                    </div>
                    <div className='flex flex-col w-full gap-6'>
                        <h2 className='font-bold'>Tipos de {toggle === 0 ? 'Pagamento' : toggle === 1 ? 'Ocorrência' : 'Cancelamento'}</h2>
                        <div className='flex flex-col w-full gap-4'>
                            {
                                listas && listas[toggle] && listas[toggle].map((ele: string, index: number) => {
                                    return (
                                        <div key={index} className='relative flex justify-between w-full p-2 border-2 border-black rounded-[12px]'>
                                            <p>{ele}</p>
                                            <div onClick={() => remove(ele, index)} className='w-6 aspect-square bg-[var(--red)] rounded-[12px] flex justify-center items-center cursor-pointer'><img src={Bin} alt="bin" className='w-4' /></div>
                                        </div>
                                    )
                                })
                            }
                            <div className='flex gap-4'>
                                <input type="text" value={newEle} onChange={(e) => setNewEle(e.target.value)} className='relative w-full px-2 py-1 border-2 border-black rounded-[12px]' placeholder='| Adicionar' />
                                <div onClick={add} className='w-12 aspect-square bg-[var(--green)] rounded-[12px] flex justify-center items-center cursor-pointer'>+</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    message={dialogMessage}
                    opened={dialogOpen}
                    onDialog={closeDialog}
                    onClose={dialogOnClose}
                />
            </div>
        </div>
    );
}

