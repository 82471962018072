import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, orderBy, query } from 'firebase/firestore';
import { exportExcel } from '../components/export';
import DownloadIcon from '../../content/imgs/icons/download.png';
import Loading from '../components/loading';

export default function CreateUser() {
    const { username = "" } = useParams();
    const navigate = useNavigate();

    const [user, setUser] = useState<any>(null);

    const [history, setHistory] = useState<any>(null);
    const [index, setIndex] = useState(0);

    useEffect(() => {
        async function getClientCard() {
            const historyCollectionRef = collection(db, `users/${username}/history`);
            const q = query(historyCollectionRef, orderBy("__name__"));
            getDocs(q).then((querySnapshot: any) => {
                const historyDocuments = querySnapshot.docs.map((doc: any) => {
                    return {
                        ...doc.data()
                    };
                });
                setHistory(historyDocuments.reverse())
            }).catch((error) => {
                console.error("Error getting documents: ", error);
            });
        }
        if (username)
            getClientCard();
    }, [username]);

    useEffect(() => {
        async function getUser() {
            const docRef = doc(db, 'users', username);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists())
                setUser(docSnap.data());
        }
        getUser();
    }, [username]);

    function handleExport() {
        exportExcel(history, username);
    }

    function getSimpleDate(date: string) {
        const day = date.split('/')[0];
        const month = date.split('/')[1];
        if (day === 'NaN') return 'Erro'
        return `${day}/${month}`;
    }

    if (!user || !history) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>ATIVIDADE</h1>
                <div className='relative text-center flex gap-10 text-[var(--primary)] font-mybold justify-center'>
                    {<span className={`cursor-pointer text-[1.2rem] font-bold ${index === history.length - 1 && 'opacity-0'}`} onClick={() => { if (index !== history.length - 1) setIndex((prev) => prev + 1) }}>&lt;&lt;</span>}
                    <div className="inline-block">
                        {
                            index !== history.length - 1 ?
                                getSimpleDate(history[index]?.actionDate)
                                :
                                `Utilizador Criado`
                        }
                    </div>
                    {<span className={`cursor-pointer text-[1.2rem] font-bold ${index === 0 && 'opacity-0'}`} onClick={() => { if (index !== 0) setIndex((prev) => prev - 1) }}>&gt;&gt;</span>}
                </div>
                {
                    index !== history.length - 1 ?
                        <>
                            <p className="font-bold">Detalhes da ação:</p>
                            <div className="flex items-center justify-between gap-4">
                                <label className="w-1/3">
                                    ID Reserva:
                                </label>
                                <div className="w-2/3">
                                    {history[index]?.idClient || "Campo vazio"}
                                </div>
                                <button onClick={() => navigate(`/consulta/${history[index]?.idClient}`)} className='w-[5rem] text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-white flex gap-2 items-center justify-center'>
                                    VER
                                </button>
                            </div>
                            <div className="flex items-center gap-4">
                                <label className="w-1/3">
                                    Alocação:
                                </label>
                                <div className="w-2/3">
                                    {history[index]?.alocation || "Campo vazio"}
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <label className="w-1/3">
                                    Ação:
                                </label>
                                <div className="w-2/3">
                                    {history[index]?.action || "Campo vazio"}
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <label className="w-1/3">
                                    Hora:
                                </label>
                                <div className="w-2/3">
                                    {history[index]?.actionDate || "Campo vazio"}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="flex items-center gap-4">
                                <label className="w-1/3 font-bold">
                                    Data de criação:
                                </label>
                                <div className="w-2/3">
                                    {(history[index]?.actionDate).toString() || "Campo vazio"}
                                </div>
                            </div>
                        </>
                }
                <div className='flex justify-center w-full'>
                    <button onClick={handleExport} className='px-6 text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black flex gap-2 items-center justify-center'>
                        EXPORTAR
                        <img src={DownloadIcon} alt="icon" className='w-6' />
                    </button>
                </div>
            </div>
        </>
    );
}

