import React, { useContext, useEffect, useRef, useState } from "react";
import Logo from "../content/imgs/logos/logo.png";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Dialog from "./components/alertDialog";
import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { ClientsContext } from "../contexts/Context";

export default function SignUp() {
  const selectRef = useRef<HTMLDivElement>(null);
  const context = useContext(ClientsContext);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const [selectedCity, setSelectedCity] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [parks, setParks] = useState<string[]>([]);
  const [parksPerCity, setParksPerCity] = useState<any>({});

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOnClose, setDialogOnClose] = useState("");

  useEffect(() => {
    setParks([]);
  }, [selectedCity]);

  useEffect(() => {
    async function fetchBrandsAndCities() {
      const allSettings: any = {};
      const settingsDocRef = await getDoc(doc(db, "settings", "cities"));
      if (settingsDocRef.exists()) {
        const settingsData = settingsDocRef.data();
        for (const key in settingsData) {
          allSettings[key] = settingsData[key];
        }
      }
      setParksPerCity(allSettings);
      setSelectedCity(Object.keys(allSettings)[0]);
    }
    fetchBrandsAndCities();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef, setIsDropdownOpen]);

  const openDialog = (message: string, onClose: any) => {
    setDialogMessage(message);
    setDialogOpen(true);
    setDialogOnClose(() => onClose);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) navigate("/home");
    });
    return () => unsubscribe();
  }, [navigate]);

  const signup = async () => {
    if (name === "" || email === "" || password === "") {
      openDialog("Preencha todos os campos.", () => null);
      return;
    }
    await createUserWithEmailAndPassword(
      auth,
      `${email.toLowerCase()}@multipark.pt`,
      password
    )
      .then(async () => {
        try {
          // Create user document in firestore
          const usersRef = collection(db, "users");
          const newUserDocRef = await addDoc(usersRef, {
            email: email.toLowerCase(),
            name: name,
            type: "inactive",
            points: 0,
            parks: parks,
            cities: [selectedCity],
          });
          // Add user to history
          const userId = newUserDocRef.id;
          const now = new Date().toISOString();
          const historyRef = collection(db, `users/${userId}/history`);
          const newHistoryDocRef = doc(historyRef, now);
          await setDoc(newHistoryDocRef, {
            email: email.toLowerCase(),
            idClient: "0",
            action: "Registo do user",
            actionDate: now,
            date: now,
            points: 0,
            type: "inactive",
            parks: parks,
            cities: [selectedCity],
          });
          navigate("/home");
        } catch (err) {
          console.error("Erro ao criar user: ", err);
        }
      })
      .catch(() => {
        openDialog(
          "Email ou password inválidos. Password deve ter 6 caractéres no mínimo.",
          () => null
        );
        return;
      });
  };

  const handleSelectOption = (ele: any) => {
    setParks((prev: any) => {
      if (prev.some((park: string) => park === ele)) {
        return prev.filter((park: any) => park !== ele);
      } else {
        return [...prev, ele];
      }
    });
  };

  return (
    <>
      <img src={Logo} alt="logo" className="w-[10rem] cinco:w-[14rem] pt-10" />
      <h1 className="text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem]">
        REGISTO
      </h1>
      <div className="flex flex-col gap-4">
        <input
          type="text"
          value={name}
          placeholder="| Name"
          onChange={(e) => setName(e.target.value)}
          className="input"
        />
        <input
          type="text"
          value={email}
          placeholder="| Username"
          onChange={(e) => setEmail(e.target.value)}
          className="lowercase input"
        />
        <input
          type="password"
          value={password}
          placeholder="| Password"
          onChange={(e) => setPassword(e.target.value)}
          className="input"
        />
        <div>
          <p>Cidade</p>
          <select
            name="city"
            value={selectedCity}
            className="select w-full cinco:w-[20rem]"
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            {Object.keys(parksPerCity)?.map((city: string) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div>
          <p>Parques</p>
          <div
            className="relative w-full h-10 px-6 py-2 border-2 border-black rounded-[12px] cursor-pointer"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            ref={selectRef}
          >
            {parks.length} {`selecionado${parks.length === 1 ? "" : "s"}`}
            {isDropdownOpen && (
              <div className="absolute w-full left-0 top-10 bg-white border-2 border-black rounded-md z-10 h-[15rem] overflow-y-scroll">
                {parksPerCity[selectedCity] &&
                  parksPerCity[selectedCity].map((ele: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSelectOption(ele);
                      }}
                    >
                      <div
                        className={`w-4 h-4 border-[1px] border-black rounded-[4px] flex justify-center items-center ${
                          parks.some((park: string) => park === ele)
                            ? "bg-[var(--primary)]"
                            : ""
                        }`}
                      >
                        <span
                          className={`${
                            parks.some((park: string) => park === ele)
                              ? "flex"
                              : "hidden"
                          } text-white text-[.8rem]`}
                        >
                          X
                        </span>
                      </div>
                      <p className="ml-2 text-sm capitalize">{ele}</p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        {context?.settings?.brandsPerCity[selectedCity] &&
          context?.settings?.brandsPerCity[selectedCity].map(
            (ele: any, index: number) => (
              <div
                key={index}
                className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelectOption(ele);
                }}
              >
                <div
                  className={`w-4 h-4 border-[1px] border-black rounded-[4px] flex justify-center items-center ${
                    parks.some((park: string) => park === ele)
                      ? "bg-[var(--primary)]"
                      : ""
                  }`}
                >
                  <span
                    className={`${
                      parks.some((park: string) => park === ele)
                        ? "flex"
                        : "hidden"
                    } text-white text-[.8rem]`}
                  >
                    X
                  </span>
                </div>
                <p className="ml-2 text-sm capitalize">{ele}</p>
              </div>
            )
          )}
        <button
          onClick={signup}
          className="text-[1rem] border-[1px] border-[transparent] bg-[var(--primary)] hover:border-[1px] hover:border-black rounded-[12px]  py-1 text-white px-10"
        >
          Registar
        </button>
      </div>
      <p>
        Já tem conta? Faça o login{" "}
        <button
          onClick={() => navigate("/")}
          className="text-[var(--primary)] cursor-pointer hover:underline"
        >
          aqui
        </button>
      </p>

      <Dialog
        message={dialogMessage}
        opened={dialogOpen}
        onDialog={closeDialog}
        onClose={dialogOnClose}
      />
    </>
  );
}
