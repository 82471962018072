import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import Dialog from '../components/alertDialog';
import Bin from '../../content/imgs/icons/bin.png';
import { db } from '../../firebase';
import { ClientsContext } from '../../contexts/Context';
import Loading from '../components/loading';

export default function AddParque() {

    const navigate = useNavigate();
    const context = useContext(ClientsContext);

    const [brands, setBrands] = useState<any>(null);

    const [city, setCity] = useState('');
    const [brand, setBrand] = useState('');
    const [parkName, setParkName] = useState('');
    const [fila, setFila] = useState(0);
    const [lugares, setLugares] = useState(0);
    const [locationName, setLocationName] = useState('');
    const [location, setLocation] = useState('');
    const [unavailableSpots, setUnavailableSpots] = useState<any>([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (context?.settings) {
            setBrands(context?.settings?.brands);
            setCity(context?.user?.selectedCity);
        }
    }, [context?.settings, context?.user?.selectedCity]);

    async function add() {
        if (!city || !brand || !locationName || !location) {
            openDialog('Preencha todos os campos.', () => null)
            return;
        }
        if (fila <= 0 || lugares <= 0) {
            openDialog('Número de filas ou lugares inválido.', () => null)
            return;
        }
        for (let i = 0; i < unavailableSpots.length; i++) {
            const ele = unavailableSpots[i];
            if (ele.row <= 0 || ele.spot <= 0 || ele.row > fila || ele.spot > lugares) {
                openDialog('Fila/Lugar indiponível deve ser superior a 0 e inferior ao número de filas/lugares', () => null);
                return;
            }
        }
        if (!window.confirm(`Tem a certeza que quer criar o parque '${parkName}'?`)) {
            return;
        }
        try {
            const brandDocRef = doc(db, city, brand);
            const brandDocSnap = await getDoc(brandDocRef);
            if (!brandDocSnap.exists()) {
                throw new Error(`Document ${brand} does not exist!`);
            }
            let parksArray = brandDocSnap.data().parks;

            // Update the park's properties
            parksArray.push({
                name: parkName,
                locationLink: location,
                locationName: locationName,
                unavailableSpots: unavailableSpots,
                rows: Array.from({ length: fila }, (_, i) => i + 1),
                spots: Array.from({ length: lugares }, (_, i) => i + 1)
            });

            // Save the updated parks array back to Firestore
            await updateDoc(brandDocRef, { parks: parksArray });

            openDialog(`Parque '${parkName}' criado com sucesso.`, () => navigate('/settings/parques'));

        } catch (error) {
            console.error("Error editing park: ", error);
            openDialog(`Erro ao editar o parque '${parkName}'.`, () => {});
        }
    }

    if (!brands) {
        return (
            <Loading/>
        )
    }

    return (
        <div className='w-full pw-[8vw] flex justify-center'>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                <div className='cinco:px-[3rem] w-full flex flex-col gap-8'>
                    <div className='flex flex-col w-full gap-6'>
                        <h2 className='text-[var(--primary)] font-mybold text-[1rem] cinco:text-[1.2rem] uppercase text-center'>Adicionar parque</h2>
                        <div className='flex flex-col w-full gap-2'>
                            <div className='flex gap-4'>
                                <p><span className='font-semibold'>Cidade:</span> {city}</p>
                            </div>
                            <select
                                name="parque"
                                value={brand}
                                className="select"
                                onChange={(e) => setBrand(e.target.value)}
                            >
                                <option value="">Marca</option>
                                {
                                    brands && brands.map((park: string, index: number) => {
                                        return (
                                            <option key={index} value={park} className='uppercase'>{park}</option>
                                        )
                                    })
                                }
                            </select>
                            <input type="text" value={parkName} onChange={(e) => setParkName(e.target.value)} className='relative w-full px-2 py-1 border-2 border-black rounded-[12px]' placeholder='Nome do parque' />
                            <div className='flex w-full gap-4'>
                                <input type="number" value={fila} onChange={(e) => setFila(parseInt(e.target.value))} className='relative w-full px-2 py-1 border-2 border-black rounded-[12px]' placeholder='Nº filas' />
                                <input type="number" value={lugares} onChange={(e) => setLugares(parseInt(e.target.value))} className='relative w-full px-2 py-1 border-2 border-black rounded-[12px]' placeholder='Nº lugares' />
                            </div>
                            <input type="text" value={locationName} onChange={(e) => setLocationName(e.target.value)} className='relative w-full px-2 py-1 border-2 border-black rounded-[12px]' placeholder='Cidade | Zona' />
                            <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} className='relative w-full px-2 py-1 border-2 border-black rounded-[12px]' placeholder='Link de localização maps' />
                            <div className='flex items-center justify-between'>
                                <label className='font-semibold'>Lugares indisponíveis:</label>
                                <button onClick={() => setUnavailableSpots((prev: any) => [...prev, { row: 0, spot: 0 }])} className='px-6 text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-1 text-white uppercase'>+ add</button>
                            </div>
                            {
                                unavailableSpots && unavailableSpots.length !== 0 &&
                                <div className="flex w-full gap-4">
                                    <label className='w-full'>Fila:</label>
                                    <label className='w-full'>Lugar:</label>
                                </div>
                            }
                            {
                                unavailableSpots && unavailableSpots.length !== 0 ? unavailableSpots.map((ele: any, index: number) => {
                                    return (
                                        <div key={index} className='flex items-center w-full gap-4'>
                                            <input type="number" className='relative w-full px-2 py-1 border-2 border-black rounded-[12px]' value={ele.row} onChange={(e) => setUnavailableSpots((prev: any) => prev.map((item: any, idx: number) => idx === index ? { ...item, row: parseInt(e.target.value) } : item))} placeholder='Fila' />
                                            <input type="number" className='relative w-full px-2 py-1 border-2 border-black rounded-[12px]' value={ele.spot} onChange={(e) => setUnavailableSpots((prev: any) => prev.map((item: any, idx: number) => idx === index ? { ...item, spot: parseInt(e.target.value) } : item))} placeholder='Lugar' />
                                            <div onClick={() => setUnavailableSpots((prev: any) => prev.filter((ele: any, idx: number) => idx !== index))} className='w-8 shrink-0 h-8 bg-[var(--red)] rounded-[12px] flex justify-center items-center cursor-pointer'><img src={Bin} alt="bin" className='w-4' /></div>

                                        </div>
                                    )
                                }) :
                                    <p className='w-full text-center'>Nenhum lugar indisponível</p>
                            }
                        </div>
                        <button onClick={add} className='w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black uppercase'>+ Adicionar</button>
                    </div>
                </div>
                <Dialog
                    message={dialogMessage}
                    opened={dialogOpen}
                    onDialog={closeDialog}
                    onClose={dialogOnClose}
                />
            </div>
        </div>
    );
}

