import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import Dialog from '../components/alertDialog';

export default function Website() {

    const navigate = useNavigate();
    const [texts, setTexts] = useState<any>(null);
    const [section, setSection] = useState<any>(null);
    const [sectionKey, setSectionKey] = useState('');
    const [edit, setEdit] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                let docSnap = await getDoc(doc(db, 'website', 'home'));
                if (docSnap.exists()) setTexts(docSnap.data());
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        if (auth?.currentUser) fetchData();
    }, []);

    const handleInputChange = (key: string, value: any) => {
        setSection((prevSection: any) => ({
            ...prevSection,
            [key]: value,
        }));
    };

    const renderInputs = (section: any, path = '') => {
        // Custom sort function to prioritize 'title' and then sort 'text#' keys numerically
        const sortKeys = (a: string, b: string) => {
            if (a === 'title') return -1;
            if (b === 'title') return 1;
            if (a.startsWith('text') && b.startsWith('text')) {
                return parseInt(a.substring(4), 10) - parseInt(b.substring(4), 10);
            }
            return a.localeCompare(b);
        };

        // Get the keys of the section, sort them according to the custom logic, and then map over them
        return Object.keys(section).sort(sortKeys).map((key) => {
            const currentPath = path ? `${path}.${key}` : key;
            if (typeof section[key] === 'string') {
                return (
                    <div key={currentPath} className='flex flex-col gap-2 mb-2'>
                        <p className='capitalize'>{key}:</p>
                        <textarea
                            disabled={!edit}
                            rows={5}
                            cols={30}
                            value={section[key]}
                            placeholder={`| ${key}`}
                            onChange={(e) => handleInputChange(currentPath, e.target.value)}
                            className={`resize-none outline-none p-2 border-2 border-black rounded-[15px] no-scroll-bar ${!edit && 'bg-[rgba(.5,.5,.5,.1)]'}`}
                        />
                    </div>
                );
            } else if (typeof section[key] === 'object' && section[key] !== null) {
                return (
                    <div key={currentPath}>
                        <p className='text-lg font-bold capitalize'>{key}</p>
                        {renderInputs(section[key], currentPath)}
                    </div>
                );
            } else {
                return null; // Or handle other types as needed
            }
        });
    };

    async function updateWebsite() {
        if (!window.confirm('Tem a certeza que quer salvar as alterações?')) return;
        try {
            const docRef = doc(db, 'website', 'home');
            const updatedSectionData = { [sectionKey]: section };
            await updateDoc(docRef, updatedSectionData);
            openDialog('Website atualizado com sucesso.', () => {});
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    if (!texts) {
        return (
            <div className='flex items-center justify-center w-screen h-screen'>
                Loading...
            </div>
        )
    }

    return (
        <>
            <div className='w-full pw-[8vw] flex justify-center'>
                <button onClick={() => navigate('/website')} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
                <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                    <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem] uppercase'>HOME</h1>
                    <div className='grid grid-cols-2 gap-4 quatro:gap-4'>
                        <div onClick={() => {setSectionKey('header'); setSection(texts?.header)}} className='uppercase text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>Header</div>
                        <div onClick={() => {setSectionKey('about'); setSection(texts?.about)}} className='uppercase text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>About</div>
                        <div onClick={() => {setSectionKey('services'); setSection(texts?.services)}} className='uppercase text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>Services</div>
                        <div onClick={() => {setSectionKey('list'); setSection(texts?.list)}} className='uppercase text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>List</div>
                        <div onClick={() => {setSectionKey('rent'); setSection(texts?.rent)}} className='uppercase text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>Rent</div>
                        <div onClick={() => setEdit(!edit)} className='uppercase text-white bg-[var(--orange)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>{edit ? 'Lock' : 'Edit'}</div>
                    </div>
                    {
                        edit && sectionKey !== '' &&
                        <div className='w-full cinco:w-[19rem]'>
                            <button onClick={updateWebsite} className='w-full bg-[var(--green)] py-2 rounded-[12px] border-2 border-[transparent] hover:border-black'>Salvar alterações</button>
                        </div>
                    }
                    <div className='w-full cinco:w-[19rem] flex flex-col gap-4'>
                        {section && renderInputs(section)}
                    </div>
                </div>
            </div>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}

