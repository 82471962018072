import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import Dialog from '../components/alertDialog';

export default function Website() {

    const navigate = useNavigate();
    const [texts, setTexts] = useState<any>(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                let docSnap = await getDoc(doc(db, 'website', 'terms'));
                if (docSnap.exists()) setTexts(docSnap.data().terms);
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        if (auth?.currentUser) fetchData();
    }, []);

    async function updateWebsite() {
        if (!window.confirm('Tem a certeza que quer salvar as alterações?')) return;
        try {
            const docRef = doc(db, 'website', 'terms');
            await updateDoc(docRef, {terms: texts});
            openDialog('Termos atualizados com sucesso.', () => {});
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    if (!texts) {
        return (
            <div className='flex items-center justify-center w-screen h-screen'>
                Loading...
            </div>
        )
    }

    return (
        <>
            <div className='w-full pw-[8vw] flex justify-center'>
                <button onClick={() => navigate('/website')} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
                <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                    <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem] uppercase'>Terms</h1>
                    <textarea className="border-black border-2 rounded-[15px] p-4 resize-none w-full h-[35rem] no-scroll-bar" value={texts} onChange={(e) => setTexts(e.target.value)} placeholder='| Terms' />
                    <div className='w-full cinco:w-[19rem]'>
                        <button onClick={updateWebsite} className='w-full bg-[var(--green)] py-2 rounded-[12px] border-2 border-[transparent] hover:border-black'>Salvar alterações</button>
                    </div>
                </div>
            </div>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}

