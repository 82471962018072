import React from 'react';
import { useNavigate } from 'react-router-dom';
import AirparkLogo from '../../content/imgs/logos/airpark.png';
import RedParkLogo from '../../content/imgs/logos/redpark.png';
import SkyparkLogo from '../../content/imgs/logos/skypark.png';
import MapIcon from '../../content/imgs/icons/map-w.png';

export default function Card({ ...props }) {
    const navigate = useNavigate();
    const park = props?.park;

    function getLogo() {
        if (props?.brand === 'skypark') return SkyparkLogo;
        else if (props?.brand === 'airpark') return AirparkLogo;
        else if (props?.brand === 'redpark') return RedParkLogo;
        else return AirparkLogo;
    }

    function getBackgroundColor() {
        if (props?.brand === 'airpark') return 'bg-[var(--primary)]';
        else if (props?.brand === 'redpark') return 'bg-[var(--red)]';
        else if (props?.brand === 'skypark') return 'bg-[var(--purple)]';
        else return 'bg-[var(--primary)]';
    }

    return (
        <div onClick={() => navigate(`/parques/${typeof park === 'string' ? 'externo' : props.brand}/${typeof park === 'string' ? park : park.name}`, { state: { park: park } })} className={`${getBackgroundColor()} rounded-[15px] p-4 cinco:p-6 w-full h-[10rem] flex justify-between items-center text-white uppercase font-bold cursor-pointer gap-4 border-[transparent] border-4 hover:border-black flex-col quatro:flex-row`}>
            <div className='flex flex-row justify-between w-full gap-4 quatro:flex-col quatro:justify-start'>
                <p className='text-[1.2rem]'>{typeof park === 'string' ? park : park?.name}</p>
                {
                    park?.locationName &&
                    <div className='flex items-center gap-2'>
                        <img src={MapIcon} alt="icon" className='w-8' />
                        <p>{park?.locationName}</p>
                    </div>
                }
            </div>
            {
                typeof park !== 'string' &&
                <div className='w-full h-[5rem] flex justify-center items-center'>
                    <img src={getLogo()} alt="logo" className='w-[10rem] brightness-0 invert' />
                </div>
            }
        </div>
    );
}

