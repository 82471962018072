import React from 'react';
import Logo from '../content/imgs/logos/logo.png';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    const navigate = useNavigate();
    return (
        <>
            <img src={Logo} alt="logo" className='absolute top-10 left-[50%] translate-x-[-50%] w-[10rem] cinco:w-[14rem]' />
            <div className='flex flex-col items-center justify-center h-screen gap-4'>
                <h1 className='text-[1.5rem] text-[var(--primary)] font-mybold'>404 Error</h1>
                <p>Esta página não existe</p>
                <button onClick={() => navigate(-1)} className='text-[1rem] bg-[var(--primary)] hover:border-[1px] hover:border-black rounded-[12px]  px-10 py-1 border-[1px] text-white'>Voltar</button>
            </div>
        </>
    );
}

