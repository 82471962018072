import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Logo from "../../content/imgs/logos/logo.png";

export default function Loading() {
  return (
    <div className="bg-[var(--primary)] text-white flex flex-col gap-6 items-center justify-center w-screen h-screen text-[1.5rem]">
      <img src={Logo} alt="logo" className="w-[10rem] cinco:w-[14rem] animate-pulse brightness-0 invert" />
      <CircularProgress color="inherit" />
    </div>
  );
}
