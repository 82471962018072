import Phone from "../../content/imgs/icons/phone-w.png";
import Map from "../../content/imgs/icons/map-w.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Card({ ...props }) {
  const navigate = useNavigate();
  const [client, setClient] = useState<any>(null);

  useEffect(() => {
    setClient(props?.client);
  }, [props?.client]);

  function selectInfo() {
    if (props.type === "consulta" || props.type === "caixa")
      return client?.stats;
    else if (
      props.type === "entregas" ||
      props.type === "movimentacao" ||
      props.type === "parque"
    )
      return (client?.checkOut).replace(",", "");
    else if (client?.stats === "em recolha") return client?.carInfo;
    else return (client?.checkIn).replace(",", "");
  }

  function getBackgroundColor() {
    if (client?.parkBrand.toLowerCase() === "airpark")
      return "bg-[var(--primary)]";
    else if (client?.parkBrand.toLowerCase() === "redpark")
      return "bg-[var(--red)]";
    else if (client?.parkBrand.toLowerCase() === "skypark")
      return "bg-[var(--purple)]";
    else return "bg-[var(--primary)]";
  }

  function getValue() {
    if (client?.correctedPrice) return client.correctedPrice;
    if (client?.priceOnDelivery) return client.priceOnDelivery;
    return client?.bookingPrice;
  }

  function getExtraServices() {
    if (client?.extraServices && client.extraServices.length > 0) {
      // if the user has an extraServices with flex on the pt variable, it shows flex, plus the total amount of extra services,
      // if not, show the 1st extra services, and then if there are more than 1, show the number of extra services
      if (client?.extraServices.some((service:any) => service.pt.includes("flex"))) {
        return (
          <div className="flex justify-end text-[.8rem] text-end">
            <p className="uppercase max-w-[5rem] text-ellipsis overflow-hidden">{client?.extraServices[0]?.pt}</p>
            {client?.extraServices.length > 1 ? (
              <p className="uppercase">
                , +{client?.extraServices.length - 1} extras
              </p>
            ) : null}
          </div>
        );
      } else {
        return (
          <div className="flex justify-end text-[.8rem] text-end">
            <p className="uppercase max-w-[5rem] text-ellipsis overflow-hidden">{client?.extraServices[0]?.pt}</p>
            {client?.extraServices.length > 1 ? (
              <p className="uppercase">
                , +{client?.extraServices.length - 1}
              </p>
            ) : null}
          </div>
        );
      }
    }
    return null;
  }

  function getPark() {
    if (props.type === "parque") {
      return `${
        client?.row
          ? `${client?.row || ""} - ${client?.spot || ""}`
          : "Sem lugar"
      }`;
    }
    if (client?.park.includes(":")) {
      return `${client?.park.split(":")[0]}, ${client?.park.split(":")[1]} ${
        client?.row ? `${client?.row || ""}:${client?.spot || ""}` : ""
      }`;
    }
  }

  if (!client) {
    return (
      <div
        className={`bg-[var(--primary)] rounded-[25px] p-2 quatro:p-4 w-full h-[10rem] flex text-white uppercase font-bold cursor-pointer gap-4 border-4 border-[transparent] hover:border-black text-[.9rem] quatro:text-[1rem]`}
      >
        <p>A carregar...</p>
      </div>
    );
  }

  return (
    <div
      onClick={() =>
        props.href
          ? navigate(`/${props.href}/${client?.idClient}`)
          : navigate(`/consulta/${client?.idClient}`)
      }
      className={`${getBackgroundColor()} rounded-[15px] p-2 quatro:p-4 w-full h-[10rem] flex text-white uppercase font-bold cursor-pointer gap-4 border-4 border-[transparent] hover:border-black text-[.8rem] small:text-[.9rem]`}
    >
      <div className="w-full h-full flex flex-col justify-between max-w-[6rem]">
        <p className="text-white">{selectInfo()}</p>
        <div>
          <p className="truncate">
            {client?.name && client?.name.split(" ")[0]}
          </p>
          <p className="truncate">
            {client?.lastname &&
              client?.lastname.split(" ")[
                client?.lastname.split(" ").length - 1
              ]}
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-between w-[11rem] h-full">
        <p className="quatro:max-w-[6rem] max-w-[8rem] overflow-hidden text-ellipsis">
          {client?.stats === "reservado" || client?.stats === "em recolha"
            ? client?.deliveryName
            : client?.park
            ? getPark()
            : "Sem parque"}
        </p>
        {client?.stats !== "cancelado" && client?.stats !== "entregue" ? (
          <p className="overflow-hidden text-ellipsis whitespace-nowrap text-[.8rem]">
            Voo: {client?.returnFlight || "Nenhum"}
          </p>
        ) : null}
        <div>
          <p className="uppercase">{client?.licensePlate}</p>
        </div>
      </div>
      <div className="flex flex-col items-end justify-between w-full h-full">
        <div>
          <p className="text-[var(--green)]">
            {client?.alocation ? `AL: ${client?.alocation}` : "Sem ALOC"}
          </p>
        </div>
        {props.type === "movimentacao" ? null : props.type !== "caixa" ? (
          <div className="flex flex-col gap-2">
            <div className="flex flex-col justify-end gap-1 small:flex-row">
              {getExtraServices()}
            </div>
            <div className="flex gap-1 cinco:gap-3">
              <img
                onClick={() => window.open(client?.deliveryLocation, "_blank")}
                src={Map}
                alt="icon"
                className="w-8 shrink-0 aspect-square"
              />
              <a href={`tel:${client?.phoneNumber}`} className="shrink-0">
                <img src={Phone} alt="icon" className="w-8 aspect-square shrink-0" />
              </a>
            </div>
          </div>
        ) : (
          <div className="text-[1.5rem]">
            <p>{getValue()} €</p>
          </div>
        )}
      </div>
    </div>
  );
}
