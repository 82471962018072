import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import Dialog from '../components/alertDialog';
import Loading from '../components/loading';

export default function Website() {

    const navigate = useNavigate();
    const [texts, setTexts] = useState<any>(null);
    const [section, setSection] = useState<any>(null);
    const [sectionKey, setSectionKey] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                let docSnap = await getDoc(doc(db, 'website', 'partnersForm'));
                if (docSnap.exists()) setTexts(docSnap.data());
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        if (auth?.currentUser) fetchData();
    }, []);

    const handleInputChange = (path: string, value: any) => {
        const keys = path.split('.');
        setSection((prevSection: any) => {
            let updated = { ...prevSection };
            let temp = updated;
            for (let i = 0; i < keys.length; i++) {
                if (i === keys.length - 1) {
                    if (keys[i].includes('[')) {
                        const match: any = keys[i].match(/(.+)\[(\d+)\]/);
                        const arrayKey = match[1];
                        const arrayIndex = parseInt(match[2]);
                        temp[arrayKey][arrayIndex] = value;
                    } else {
                        temp[keys[i]] = value;
                    }
                } else {
                    if (keys[i].includes('[')) {
                        const match: any = keys[i].match(/(.+)\[(\d+)\]/);
                        const arrayKey = match[1];
                        const arrayIndex = parseInt(match[2]);
                        temp = temp[arrayKey][arrayIndex];
                    } else {
                        temp = temp[keys[i]];
                    }
                }
            }
            return updated;
        });
    };


    const renderInputs = (section: any, path = '') => {
        const sortKeys = (a: string, b: string) => {
            if (a === 'title') return -1;
            if (b === 'title') return 1;
            if (a.startsWith('text') && b.startsWith('text')) {
                return parseInt(a.substring(4), 10) - parseInt(b.substring(4), 10);
            }
            return a.localeCompare(b);
        };
        // Get the keys of the section, sort them according to the custom logic, and then map over them
        return Object.keys(section).sort(sortKeys).map((key) => {
            const currentPath = path ? `${path}.${key}` : key;
            if (Array.isArray(section[key])) {
                return (
                    <div key={currentPath}>
                        <p className='text-lg font-bold capitalize'>{key}</p>
                        {section[key].map((item: any, index: number) => {
                            const itemPath = `${currentPath}[${index}]`;
                            if (typeof item === 'string') {
                                return (
                                    <div key={itemPath} className='flex flex-col gap-2 mb-2'>
                                        <textarea
                                            rows={2}
                                            value={item}
                                            placeholder={`Item ${index + 1}`}
                                            onChange={(e) => handleInputChange(itemPath, e.target.value)}
                                            className={`resize-none outline-none p-2 border-2 border-black rounded-[15px] no-scroll-bar`}
                                        />
                                    </div>
                                );
                            } else {
                                // If the item itself is an object, call renderInputs recursively
                                return renderInputs(item, itemPath);
                            }
                        })}
                    </div>
                );
            } else if (typeof section[key] === 'string') {
                return (
                    <div key={currentPath} className='flex flex-col gap-2 mb-2'>
                        <textarea
                            rows={5}
                            cols={30}
                            value={section[key]}
                            placeholder={`| ${key}`}
                            onChange={(e) => handleInputChange(currentPath, e.target.value)}
                            className={`resize-none outline-none p-2 border-2 border-black rounded-[20px] no-scroll-bar`}
                        />
                    </div>
                );
            } else if (typeof section[key] === 'object' && section[key] !== null) {
                return (
                    <div key={currentPath}>
                        <p className='text-lg font-bold capitalize'>{key}</p>
                        {renderInputs(section[key], currentPath)}
                    </div>
                );
            } else {
                return null;
            }
        });
    };

    const handleBaseValueChange = (value: any) => {
        setTexts((prevTexts: any) => ({
            ...prevTexts,
            baseValue: value,
        }));
    };

    const renderOneInput = () => {
        return (
            <div className='flex flex-col gap-2 mb-2'>
                <p className='capitalize'>baseValue:</p>
                <input
                    type="number"
                    value={texts?.baseValue}
                    placeholder="Base Value"
                    onChange={(e) => handleBaseValueChange(e.target.value)}
                    className={`resize-none outline-none p-2 border-2 border-black rounded-[20px] no-scroll-bar`}
                />
            </div>
        );
    };


    async function updateWebsite() {
        if (!window.confirm('Tem a certeza que quer salvar as alterações?')) return;
        try {
            const docRef = doc(db, 'website', 'form');
            let updatedSectionData;
            if (sectionKey === 'baseValue') updatedSectionData = { baseValue: parseInt(texts.baseValue) };
            else updatedSectionData = { [sectionKey]: section };
            await updateDoc(docRef, updatedSectionData);
            openDialog('Form atualizado com sucesso.', () => {});
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    }

    if (!texts) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            <div className='w-full pw-[8vw] flex justify-center'>
                <button onClick={() => navigate('/website')} className='absolute top-8 left-[8vw] font-mybold'>⬅ VOLTAR</button>
                <div className='w-full cinco:w-[25rem] flex flex-col items-center gap-10 py-20 cinco:py-8'>
                    <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem] uppercase'>FORM</h1>
                    <div className='grid grid-cols-2 gap-4 quatro:gap-4'>
                        <div onClick={() => { setSectionKey('ramos'); setSection(texts?.ramos) }} className='uppercase text-center text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>Ramos</div>
                        <div onClick={() => { setSectionKey('tipos'); setSection(texts?.tipos) }} className='uppercase text-center text-white bg-[var(--primary)] rounded-[20px] w-[7rem] h-[5rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black'>Tipos</div>
                    </div>
                    {
                        <div className='w-full cinco:w-[19rem]'>
                            <button onClick={updateWebsite} className='w-full bg-[var(--green)] py-2 rounded-[12px] border-2 border-[transparent] hover:border-black'>Salvar alterações</button>
                        </div>
                    }
                    <div className='w-full cinco:w-[19rem] flex flex-col gap-4'>
                        {sectionKey === 'baseValue' ? renderOneInput() : section && renderInputs(section)}
                    </div>
                </div>
            </div>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}

