import React, { useContext, useEffect, useState } from "react";
import { signOut, updatePassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { ClientsContext } from "../contexts/Context";

import Logo from "../content/imgs/logos/logo.png";
import Recolhas from "../content/imgs/menu/recolhas.png";
import Entregas from "../content/imgs/menu/entregas.png";
import Caixa from "../content/imgs/menu/caixa.png";
import Movimentacao from "../content/imgs/menu/movimentacao.png";
import Ocorrencias from "../content/imgs/menu/ocorrencias.png";
import Parques from "../content/imgs/menu/parques.png";
import Procedimentos from "../content/imgs/menu/procedimentos.png";
import Users from "../content/imgs/menu/users.png";
import Consulta from "../content/imgs/menu/consulta.png";
import Settings from "../content/imgs/menu/settings.png";
// import Website from '../content/imgs/menu/website.png';
// import Premios from '../content/imgs/menu/premios.png';
import Loading from "./components/loading";

export default function Home() {
  const navigate = useNavigate();
  const context = useContext(ClientsContext);

  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [selectedCity, setSelectedCity] = useState(
    context?.user?.selectedCity || ""
  );
  const [changePassword, setChangePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (context?.user) {
      setType(context?.user.type);
      setName(context?.user.name);
    }
  }, [context?.user, context?.clients]);

  useEffect(() => {
    context?.setUser({ ...context.user, selectedCity: selectedCity });
    localStorage.setItem("selectedCity", selectedCity);
  }, [selectedCity]);

  function Button({
    image,
    text,
    href,
  }: {
    image: any;
    text: string;
    href: string;
  }) {
    return (
      <div
        onClick={() => navigate(href)}
        className="bg-[var(--primary)] rounded-[15px] w-[8rem] quatro:w-[9rem] h-[7rem] cursor-pointer flex flex-col justify-center items-center hover:border-[3px] hover:border-black"
      >
        <img src={image} alt="button" className="h-[5rem]" />
        <p className="text-white uppercase quatro:text-[.9rem] text-[.8rem]">
          {text}
        </p>
      </div>
    );
  }

  async function changePasswordHandler() {
    if (password === "" || confirmPassword === "") {
      alert("Preencha todos os campos");
      return;
    }
    if (password !== confirmPassword) {
      alert("Passwords não coincidem");
      return;
    }
    var currentUser = auth.currentUser;
    if (!currentUser) return;
    await updatePassword(currentUser, password)
      .then(() => {
        alert("Password alterada com sucesso");
        setChangePassword(false);
      })
      .catch((error) => {
        alert("Erro ao alterar a password");
        console.error(error);
      });
  }

  async function logout() {
    try {
      await signOut(auth);
      localStorage.clear();
      navigate("/");
    } catch (error) {
      alert("Log out failed.");
    }
  }

  if (type === "") {
    return <Loading />;
  }

  if (type === "inactive") {
    return (
      <div className="flex flex-col items-center justify-center w-full h-screen gap-6">
        <h1 className="text-[var(--primary)] font-mybold text-[1.2rem] uppercase text-center">
          Utilizador Inativo
        </h1>
        <p>Deve pedir permissões para aceder</p>
        <button
          onClick={logout}
          className="text-[1rem] bg-[var(--primary)] hover:border-[1px] hover:border-black rounded-[12px] px-10 py-1 text-white"
        >
          Log Out
        </button>
      </div>
    );
  }

  return (
    <div className="w-full pw-[8vw] flex justify-center">
      <div className="w-full cinco:w-[25rem] flex flex-col items-center gap-8 py-10">
        <img src={Logo} alt="logo" className="w-[10rem] cinco:w-[14rem]" />
        <h1 className="max-w-[18rem] text-center text-[var(--primary)] font-mybold text-[1.2rem] uppercase text-balance">
          Bem-vindo de volta, {name?.split(" ")[0]}
        </h1>
        {context?.user?.cities?.length > 1 && (
          <select
            name="city"
            value={selectedCity}
            className="select w-full cinco:w-[20rem] !rounded-lg"
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            {context?.user?.cities?.map((city: string) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        )}
        <div className="grid grid-cols-2 gap-4 quatro:gap-6">
          {(type === "Admin" ||
            type === "SuperVisor" ||
            type === "Chefe" ||
            type === "Condutor") && (
            <Button image={Recolhas} text="Recolhas" href="/recolhas" />
          )}
          {(type === "Admin" ||
            type === "SuperVisor" ||
            type === "Chefe" ||
            type === "Condutor") && (
            <Button image={Entregas} text="Entregas" href="/entregas" />
          )}
          {(type === "Admin" || type === "SuperVisor" || type === "Chefe") && (
            <Button image={Caixa} text="Caixa" href="/caixa" />
          )}
          <Button
            image={Movimentacao}
            text="Movimentação"
            href="/movimentacao"
          />
          <Button
            image={Ocorrencias}
            text="Ocorrências"
            href="/ocorrencia/geral"
          />
          {(type === "Admin" || type === "SuperVisor" || type === "Chefe") && (
            <Button image={Consulta} text="Consulta" href="/consulta" />
          )}
          <Button image={Parques} text="Parques" href="/parques" />
          <Button
            image={Procedimentos}
            text="Procedimentos"
            href="/procedimentos"
          />
          {(type === "Admin" || type === "SuperVisor") && (
            <Button image={Users} text="Utilizadores" href="/users" />
          )}
          {type === "Admin" && (
            <Button image={Settings} text="Definições" href="/settings" />
          )}

          {/* {type === 'Admin' && <Button image={Website} text="Website" href="/website"/>} */}
          {/* <Button image={Premios} text="Prémios" href="/premios"/> */}
        </div>
        <button
          onClick={logout}
          className="text-[1rem] bg-[var(--primary)] hover:border-[1px] hover:border-black rounded-[12px] px-10 py-1 text-white"
        >
          Sair
        </button>
        <p>
          Alterar palavra-passe{" "}
          <button
            onClick={() => setChangePassword(true)}
            className="text-[var(--primary)] cursor-pointer hover:underline"
          >
            aqui
          </button>
        </p>
        {changePassword && (
          <div className="flex flex-col items-center gap-4">
            <input
              type="password"
              value={password}
              placeholder="| Password"
              onChange={(e) => setPassword(e.target.value)}
              className="input"
            />
            <input
              type="password"
              value={confirmPassword}
              placeholder="| Confirmar Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="input"
            />
            <div className="flex w-full gap-4">
              <button
                onClick={() => setChangePassword(false)}
                className="text-[1rem] border-[transparent] border-[2px] bg-[var(--primary)] hover:border-[2px] hover:border-black rounded-[12px]  py-1 text-white px-10"
              >
                Cancelar
              </button>
              <button
                onClick={changePasswordHandler}
                className="text-[1rem] border-[transparent] border-[2px] bg-[var(--green)] hover:border-[2px] hover:border-black rounded-[12px]  py-1 text-black px-10"
              >
                Alterar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
