import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../components/clientCard";
import { updateClient } from "../../server";
import Dialog from "../components/alertDialog";
import { ClientsContext } from "../../contexts/Context";
import Loading from "../components/loading";

export default function Recolha() {
  const { id = 0 } = useParams();
  const navigate = useNavigate();
  const context = useContext(ClientsContext);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOnClose, setDialogOnClose] = useState("");

  const openDialog = (message: string, onClose: any) => {
    setDialogMessage(message);
    setDialogOpen(true);
    setDialogOnClose(() => onClose);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const [client, setClient] = useState<any>(null);
  const [nome, setNome] = useState("");
  const [nomeFatura, setNomeFatura] = useState("");
  const [nif, setNif] = useState("");
  const [valor, setValor] = useState("");
  const [pagamento, setPagamento] = useState("");
  const [correcao, setCorrecao] = useState("");
  const [acerto, setAcerto] = useState("");

  useEffect(() => {
    async function getClientCard() {
      if (
        !context?.clients ||
        context?.clients.length === 0 ||
        !context?.clients.some(
          (client: any) => client?.idClient === id.toString()
        )
      ) {
        context?.getClients("idClient", [id.toString()]);
        return;
      }
      const data = (context?.clients).filter(
        (client: any) => (client?.idClient).toString() === id.toString()
      );
      if (data.length === 1) setClient(data[0]);
    }
    getClientCard();
  }, [id, context?.clients, context]);

  useEffect(() => {
    if (client) {
      setPagamento(client?.paymentMethod);
      setValor(getValue(client));
      setCorrecao(client?.correction || "");
      setNome(context?.user.email || "");
      setNomeFatura(client?.taxName || "");
      setNif(client?.taxNumber || "");
    }
  }, [client, context?.user.email]);

  function getValue(client: any) {
    if (client?.correctedPrice) return client.correctedPrice;
    if (client?.priceOnDelivery) return client.priceOnDelivery;
    return client?.bookingPrice;
  }

  function acertar(e: any) {
    const newValue = e.target.value;
    setCorrecao(newValue);
    if (newValue !== "") setAcerto((newValue - parseInt(valor)).toString());
    else setAcerto("");
  }

  function formatDate(isoDateString: string) {
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  }

  async function alterar() {
    if (correcao === "") {
      openDialog("Preencha o campo correção.", () => null);
      return;
    }
    if (valor === "" || valor === undefined) {
      openDialog(
        "Não é possível corrigir, o valor ainda não foi atribuído.",
        () => null
      );
      return;
    }
    const data = { ...client };
    data["correction"] = correcao;
    data["correctedPrice"] = correcao;
    data["actionUser"] = context?.user.email || "unknown";
    data["actionDate"] = formatDate(new Date().toISOString());
    data["action"] = "Correção da caixa";
    try {
      updateClient(
        context,
        navigate,
        data,
        context?.user.id || "unknown",
        "Correção da caixa",
        openDialog
      );
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  if (!client) {
    return <Loading />;
  }

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          ALTERAÇÕES DE VALOR
        </h1>
        <Card href={null} client={client} type={"caixa"} />
        <div className="flex justify-between gap-4">
          <div className="flex flex-col w-full gap-4">
            <p>Nome P/ Fatura:</p>
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={nomeFatura}
              onChange={(e) => setNomeFatura(e.target.value)}
              placeholder="| Nome Fatura"
            />
            <p>Preço da reserva:</p>
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={client?.bookingPrice}
              placeholder="| Valor"
            />
            <p>Preço na entrega:</p>
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={client?.priceOnDelivery}
              placeholder="| Valor"
            />
            <p>Nova Correção:</p>
            <input
              className="input "
              type="number"
              value={correcao}
              onChange={(e) => acertar(e)}
              placeholder={valor}
            />
          </div>
          <div className="flex flex-col justify-end w-full gap-4">
            <p>NIF P/ Fatura:</p>
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={nif}
              onChange={(e) => setNif(e.target.value)}
              placeholder="| NIF"
            />
            <p>Pagamento:</p>
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={pagamento}
              onChange={(e) => setPagamento(e.target.value)}
              placeholder="| Pagamento"
            />
            <p>Preço corrigido:</p>
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={client?.correctedPrice}
              placeholder="| Valor"
            />
            <p>Acerto:</p>
            <input
              className="input bg-[rgba(.5,.5,.5,.1)]"
              disabled
              type="text"
              value={acerto}
              onChange={(e) => setAcerto(e.target.value)}
              placeholder="| Acerto"
            />
          </div>
        </div>
        <div className="flex gap-4 mt-4">
          <button
            onClick={alterar}
            className="w-full text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px]  py-2 text-black"
          >
            ALTERAR
          </button>
          <button
            onClick={() => navigate("/caixa")}
            className="w-full text-[1rem] bg-[var(--yellow)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px]  py-2 text-black"
          >
            CANCELAR
          </button>
        </div>
      </div>
      <Dialog
        message={dialogMessage}
        opened={dialogOpen}
        onDialog={closeDialog}
        onClose={dialogOnClose}
      />
    </>
  );
}
