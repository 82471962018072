import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Card({ ...props }) {
    const navigate = useNavigate();

    return (
        <div onClick={() => props.href ? navigate(`/${props.href}/${props.username}`) : null} className='bg-[var(--primary)] rounded-[15px] p-4 cinco:p-6 w-full h-[10rem] flex flex-col justify-between items-center text-white uppercase font-bold cursor-pointer gap-4 border-[transparent] border-4 hover:border-black'>
            <p className='text-[1.2rem] text-left w-full'>{props?.name}</p>
            <div className='flex justify-between w-full gap-4'>
                <p className='text-[var(--green)]'>{props?.type}</p>
                <p><span className='text-[var(--yellow)]'>{props?.points}</span> pontos</p>
            </div>
        </div>
    );
}

