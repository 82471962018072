import React, { useEffect, useState } from 'react';
import Logo from '../content/imgs/logos/logo.png';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Dialog from './components/alertDialog';

export default function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message:string, onClose:any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async user => {
			if (user)
				navigate('/home');
		});
		return () => unsubscribe();
	}, [navigate]);

    const login = async () => {
        if (email === '' || password === '') {
            openDialog('Preencha todos os campos.', () => null)
            return;
        }
        await signInWithEmailAndPassword(auth, `${email.toLowerCase()}@multipark.pt`, password)
            .then(async () => {
                navigate('/home');
            })
            .catch(() => {
                openDialog('Email ou password incorretos.', () => null)
                return;
            });
    }

    return (
        <>
            <img src={Logo} alt="logo" className='w-[10rem] cinco:w-[14rem] py-10' />
            <h1 className='text-[var(--primary)] font-mybold text-[1.2rem] cinco:text-[1.5rem]'>BACK-OFFICE</h1>
            <input type="text" value={email} placeholder='| Username' onChange={(e) => setEmail(e.target.value)} className="lowercase input" />
            <input type="password" value={password} placeholder='| Password' onChange={(e) => setPassword(e.target.value)} className="input" />
            <button onClick={login} className='text-[1rem] border-[transparent] border-[2px] bg-[var(--primary)] hover:border-[2px] hover:border-black rounded-[12px]  py-1 text-white px-10'>Entrar</button>
            <p>Funcionário? Regista-te <button onClick={() => navigate('/signup')} className='text-[var(--primary)] cursor-pointer hover:underline'>aqui</button></p>
            <Dialog 
                message={dialogMessage} 
                opened={dialogOpen} 
                onDialog={closeDialog} 
                onClose={dialogOnClose}
            />
        </>
    );
}

