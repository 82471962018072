import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sos from "../../content/imgs/icons/sos.png";
import Phone from "../../content/imgs/icons/phone-b.png";
import Email from "../../content/imgs/icons/email-b.png";
import Dialog from "../components/alertDialog";
import { ClientsContext } from "../../contexts/Context";
import emailjs from "emailjs-com";
import Loading from "../components/loading";

export default function Ocorrencias() {
  const context = useContext(ClientsContext);
  const navigate = useNavigate();

  const [listaDeOcorrencias, setListaDeOcorrencias] = useState<any>(null);
  const [ocorrencia, setOcorrencia] = useState("");
  const [text, setText] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOnClose, setDialogOnClose] = useState("");

  const openDialog = (message: string, onClose: any) => {
    setDialogMessage(message);
    setDialogOpen(true);
    setDialogOnClose(() => onClose);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (context?.settings)
      setListaDeOcorrencias(context?.settings?.tipoDeOcorrencia?.all);
  }, [context?.settings]);

  async function submit() {
    if ((ocorrencia === "" || text === "")) {
      openDialog("Preencha todos os campos.", () => null);
      return;
    }
    // Email params
    const emailParams = {
      ocorrenciaType: ocorrencia,
      ocorrencia: text,
      idClient: "Ocorrencia Geral",
      carLocation: `Ocorrencia Geral`,
    };
    try {
      emailjs.send(
        "service_ccpv2ev",
        "template_qp0cfzk",
        emailParams,
        "jeStFGZ-400kFvT_-"
      );
      openDialog("Ocorrência submetida com sucesso.", () => navigate(-1));
    } catch (error) {
      console.error("Error updating document:", error);
    }
  }

  if (!listaDeOcorrencias) {
    return <Loading />;
  }

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 py-20 cinco:py-8">
        <h1 className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          OCORRÊNCIAS
        </h1>
        <p className="text-[1.2rem] font-mybold text-[var(--primary)] text-center">
          REGISTO: Geral
        </p>
        <select
          name="ocorrencia"
          value={ocorrencia}
          className="select"
          onChange={(e) => setOcorrencia(e.target.value)}
        >
          <option value="">Selecionar</option>
          {listaDeOcorrencias
            .slice()
            .reverse()
            .map((ele: string, index: number) => {
              return (
                <option key={index} value={ele}>
                  {ele}
                </option>
              );
            })}
        </select>
        <textarea
          className="w-full resize-none h-[15rem] border-2 border-black rounded-[15px] p-4"
          placeholder="| Texto"
          name="ocorrencia"
          cols={30}
          rows={10}
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
        <div className="flex flex-col items-center w-full gap-4">
          <button
            onClick={() =>
              window.open(process.env.REACT_APP_OCORRENCIA_LINK, "_blank")
            }
            className="w-1/2 text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-[10px] py-2 text-white"
          >
            VIDEO/PHOTO
          </button>
          <button
            onClick={submit}
            className="w-1/2 text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-[10px] py-2 text-white"
          >
            SUBMETER
          </button>
        </div>
        <div className="flex items-center justify-between w-full gap-4 mt-4">
          <a href="tel:935625800">
            <img
              src={Phone}
              alt="phone"
              className="w-[4rem] h-[4rem] cursor-pointer"
            />
          </a>
          <a href="tel:112">
            <img src={Sos} alt="sos" className="w-[8rem] cursor-pointer" />
          </a>
          <a href="mailto:jorgetabuada@airpark.pt">
            <img
              src={Email}
              alt="email"
              className="w-[4rem] h-[4rem] cursor-pointer"
            />
          </a>
        </div>
      </div>
      <Dialog
        message={dialogMessage}
        opened={dialogOpen}
        onDialog={closeDialog}
        onClose={dialogOnClose}
      />
    </>
  );
}
