import React, { useContext, useEffect, useRef, useState } from "react";
import { db } from "../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { setDoc, doc, deleteDoc } from "firebase/firestore";
import Dialog from "../components/alertDialog";
import BinIcon from "../../content/imgs/icons/bin.png";
import { ClientsContext } from "../../contexts/Context";
import Loading from "../components/loading";
import UserCard from "../components/userCard";

export default function CreateUser() {
  const { username = "" } = useParams();
  const context = useContext(ClientsContext);
  const navigate = useNavigate();

  const [user, setUser] = useState<any>(null);
  const [name, setName] = useState<any>(null);
  const [email, setEmail] = useState("");
  const [points, setPoints] = useState(0);
  const [type, setType] = useState("");

  const [parks, setParks] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [parksOptions, setParksOptions] = useState<any>([]);
  const [citiesOptions, setCitiesOptions] = useState<any>([]);

  const selectRef = useRef<HTMLDivElement>(null);
  const selectCitiesRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isDropdownCitiesOpen, setIsDropdownCitiesOpen] =
    useState<boolean>(false);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOnClose, setDialogOnClose] = useState("");

  useEffect(() => {
    if (context?.users && username)
      setUser(context?.users.find((doc: any) => doc.email === username));
  }, [context?.users, username]);

  useEffect(() => {
    if (context?.settings) {
      setParksOptions(context?.settings?.brands);
      setCitiesOptions(context?.settings?.cities);
    }
  }, [context?.settings]);

  useEffect(() => {
    if (user) {
      setEmail(username);
      setType(user?.type);
      setName(user?.name);
      setPoints(user?.pontos || 0);
      setParks(user?.parks || []);
      setCities(user?.cities || []);
    }
  }, [user, username]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      if (
        selectCitiesRef.current &&
        !selectCitiesRef.current.contains(event.target)
      ) {
        setIsDropdownCitiesOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef, setIsDropdownOpen]);

  const openDialog = (message: string, onClose: any) => {
    setDialogMessage(message);
    setDialogOpen(true);
    setDialogOnClose(() => onClose);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleSelectOption = (ele: any) => {
    setParks((prev: any) => {
      if (prev.some((park: string) => park === ele)) {
        return prev.filter((park: any) => park !== ele);
      } else {
        return [...prev, ele];
      }
    });
  };

  const handleSelectCitiesOption = (ele: any) => {
    setCities((prev: any) => {
      if (prev.some((city: string) => city === ele)) {
        return prev.filter((city: any) => city !== ele);
      } else {
        return [...prev, ele];
      }
    });
  };

  const deleteUser = async () => {
    if (!edit && !window.confirm("Tem a certeza que quer apagar este user?")) {
      return;
    }
    // delete firebase doc
    try {
      const docRef = doc(db, "users", user?.id);
      await deleteDoc(docRef);
      openDialog(`User ${name} apagado.`, () => {
        context?.setUsers((prev: any) => {
          return prev.filter((user: any) => user.email !== username);
        });
        navigate(-1);
      });
    } catch (e) {
      console.error("Error creating document");
    }
  };

  const edit = async () => {
    if (!type || !name) {
      openDialog("Preencha todos os campos.", () => null);
      return;
    }
    try {
      // for each city selected update or create the user
      const newUser = {
        name: name,
        email: username,
        type: type || "inactive",
        pontos: points || 0,
        parks: parks,
        cities: cities,
      };
      const docRef = doc(db, "users", user?.id);
      await setDoc(docRef, newUser);
      context?.setUsers((prev: any) => {
        return prev.map((user: any) => {
          if (user.email === username) return newUser;
          return user;
        });
      });
      openDialog(`User ${name} atualizado.`, () => navigate(-1));
    } catch (e) {
      console.error("Error creating document", e);
    }
  };

  if (!parks || !user) {
    return <Loading />;
  }

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        className="absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold"
      >
        ⬅ VOLTAR
      </button>
      <div className="flex flex-col justify-center w-full gap-6 my-20 cinco:py-8">
        <UserCard
          href={null}
          username={username}
          name={name}
          type={type}
          points={points}
        />
        <div className="flex justify-center w-full">
          <button
            onClick={() => navigate(`/user/${user?.id}/history`)}
            className="px-6 text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-white"
          >
            VER HISTORICO
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <p>Nome:</p>
          <input
            type="text"
            value={name}
            placeholder="Nome"
            onChange={(e) => setName(e.target.value)}
            className="input"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p>Username:</p>
          <input
            disabled
            type="text"
            value={email}
            placeholder="| Email"
            onChange={(e) => setEmail(e.target.value)}
            className="input bg-[rgba(.5,.5,.5,.1)]"
          />
        </div>
        <div className="flex flex-col gap-2">
          <p>Tipo:</p>
          <select
            name="type"
            value={type}
            className="select"
            onChange={(e) => setType(e.target.value)}
          >
            <option value="">Selecionar</option>
            <option value={"Junior"} className="text-black bg-white">
              Júnior
            </option>
            <option value={"Condutor"} className="text-black bg-white">
              Condutor
            </option>
            <option value={"Chefe"} className="text-black bg-white">
              Chefe de Turno
            </option>
            {context?.user?.type === "Admin" && (
              <option value={"SuperVisor"} className="text-black bg-white">
                Super-Visor
              </option>
            )}
            {context?.user?.type === "Admin" && (
              <option value={"Admin"} className="text-black bg-white">
                Admin
              </option>
            )}
          </select>
        </div>

        <div className="flex flex-col gap-2">
          <p>Cidades acessíveis:</p>
          <div
            className="relative w-full h-10 px-6 py-2 border-2 border-black rounded-[12px] cursor-pointer"
            onClick={() => setIsDropdownCitiesOpen(!isDropdownCitiesOpen)}
            ref={selectCitiesRef}
          >
            {cities.length} {`selecionado${cities.length === 1 ? "" : "s"}`}
            {isDropdownCitiesOpen && (
              <div className="absolute w-full left-0 top-10 bg-white border-2 border-black rounded-md z-10 h-[15rem] overflow-y-scroll">
                {citiesOptions &&
                  citiesOptions.map((ele: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSelectCitiesOption(ele);
                      }}
                    >
                      <div
                        className={`w-4 h-4 border-[1px] border-black rounded-[4px] flex justify-center items-center ${
                          cities.some((park: string) => park === ele)
                            ? "bg-[var(--primary)]"
                            : ""
                        }`}
                      >
                        <span
                          className={`${
                            cities.some((park: string) => park === ele)
                              ? "flex"
                              : "hidden"
                          } text-white text-[.8rem]`}
                        >
                          X
                        </span>
                      </div>
                      <p className="ml-2 text-sm capitalize">{ele}</p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <p>Parques acessíveis:</p>
          <div
            className="relative w-full h-10 px-6 py-2 border-2 border-black rounded-[12px] cursor-pointer"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            ref={selectRef}
          >
            {parks.length} {`selecionado${parks.length === 1 ? "" : "s"}`}
            {isDropdownOpen && (
              <div className="absolute w-full left-0 top-10 bg-white border-2 border-black rounded-md z-10 h-[15rem] overflow-y-scroll">
                {parksOptions &&
                  parksOptions.map((ele: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center gap-2 p-2 pl-4 hover:bg-gray-100"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSelectOption(ele);
                      }}
                    >
                      <div
                        className={`w-4 h-4 border-[1px] border-black rounded-[4px] flex justify-center items-center ${
                          parks.some((park: string) => park === ele)
                            ? "bg-[var(--primary)]"
                            : ""
                        }`}
                      >
                        <span
                          className={`${
                            parks.some((park: string) => park === ele)
                              ? "flex"
                              : "hidden"
                          } text-white text-[.8rem]`}
                        >
                          X
                        </span>
                      </div>
                      <p className="ml-2 text-sm capitalize">{ele}</p>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <p>Pontos:</p>
          <input
            type="number"
            value={points}
            placeholder="Pontos"
            onChange={(e) => setPoints(parseInt(e.target.value))}
            className="input"
          />
        </div>
        <div className="flex justify-center gap-4">
          <button
            onClick={edit}
            className="w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-white"
          >
            SALVAR
          </button>
          {username !== context?.user.email && (
            <button
              onClick={deleteUser}
              className="w-full text-[1rem] bg-[var(--red)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-white flex gap-2 items-center justify-center"
            >
              APAGAR
              <img src={BinIcon} alt="icon" className="w-5" />
            </button>
          )}
        </div>
      </div>
      <Dialog
        message={dialogMessage}
        opened={dialogOpen}
        onDialog={closeDialog}
        onClose={dialogOnClose}
      />
    </>
  );
}
