import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../components/clientCard';
import { updateClient } from '../../server';
import Dialog from '../components/alertDialog';
import { auth } from '../../firebase';
import emailjs from 'emailjs-com';
import { ClientsContext } from '../../contexts/Context';
import Loading from '../components/loading';

export default function Recolha() {
    const { id = 0 } = useParams();
    const navigate = useNavigate();
    const context = useContext(ClientsContext);
    const [client, setClient] = useState<any>(null);

    const [parkData, setParkData] = useState<any>(null)
    const [parksList, setParksList] = useState<any>(null)
    const [rows, setRows] = useState<any>(null)
    const [spots, setSpots] = useState<any>(null)
    const [video, setVideo] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogOnClose, setDialogOnClose] = useState('');

    const openDialog = (message: string, onClose: any) => {
        setDialogMessage(message);
        setDialogOpen(true);
        setDialogOnClose(() => onClose);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    
    const [veiculo, setVeiculo] = useState('');
    const [data, setData] = useState('');
    const [hora, setHora] = useState('');
    const [parque, setParque] = useState('');
    const [condutor, setCondutor] = useState('');
    const [fila, setFila] = useState('');
    const [lugar, setLugar] = useState('');
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [parkingType, setParkingType] = useState('');
    const [location, setLocation] = useState<any>(null);

    useEffect(() => {
        async function getClientCard() {
            if (!context?.clients || context?.clients.length === 0
                || !context?.clients.some((client:any) => client?.idClient === id.toString())) {
                    context?.getClients('__name__', [id.toString()]);
                    return ;
                }
            const data = (context?.clients).filter((client: any) => (client?.idClient).toString() === id.toString())
            if (data.length === 1) setClient(data[0]);
        }
        getClientCard();
    }, [id, context?.clients, context]);
    
    useEffect(() => {
        async function fetchData() {
            if (context?.parks) {
                const parks = context.parks;
                const list = Object.keys(parks).reduce((acc: any, brand: string) => {
                    return [...acc, ...Object.keys(parks[brand]).map((park: string) => `${brand}:${park}`)];
                }, []);
                setParksList([...list]);
                setParkData(parks);
            }
            setVeiculo(client?.carInfo);
            setData((client?.checkIn).split(',')[0]);
            setHora((client?.checkIn).split(', ')[1]);
            setCondutor(context?.user.email || "");
            setName(client?.name);
            setLastname(client?.lastname);
            setParkingType(client?.parkingType)
            if (localStorage.getItem('formData')) fetchPrevData();
        }
        if (auth?.currentUser && client)
            fetchData();
    }, [client, context?.user.email, context?.parks]);

    useEffect(() => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by your browser');
            return;
        }
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            () => {
                alert('Unable to retrieve your location');
            }
        );
    }, []);

    function fetchPrevData() {
        const data = localStorage.getItem('formData');
        if (data) {
            const parsedData = JSON.parse(data);
            setParque(parsedData?.parque || '');
            setFila(parsedData?.fila || '');
            setLugar(parsedData?.lugar || '');
            setVeiculo(parsedData?.veiculo || '');
        }
        localStorage.removeItem('formData');
    }

    function formatDate(isoDateString: string) {
        const date = new Date(isoDateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}/${month}/${year}, ${hours}:${minutes}`;
    }

    function ocorrencia() {
        localStorage.setItem('formData', JSON.stringify({
            veiculo: veiculo,
            parque: parque,
            fila: fila,
            lugar: lugar
        }))
        navigate(`/ocorrencias/${id}`);
    }

    function isBeingUsed() {
        let cardsObject = context.clients.filter((card: any) => card?.stats === 'em recolha' && card?.stats === 'recolhido' && card?.park && card?.park === parque);
        return cardsObject.some((card: any) => card.row === fila && card.spot === lugar);
    }

    function checkIsSpotAvailable() {
        if (parkData && parque && fila && lugar) {
            const brand = parque.split(':')[0];
            const park = parque.split(':')[1];
            const row = fila;
            const spot = lugar;
            return !(parkData[brand][park].unavailableSpots.some((ele: any) => (ele.row).toString() === row && (ele.spot).toString() === spot) || isBeingUsed());
        }
        return true;
    }

    async function recolher(estado:string) {
        if (estado === "recolhido" && parque === '') {
            openDialog('Selecione um parque.', () => null)
            return;
        }
        if (estado === "recolhido" && ((fila !== '' && lugar === '') || (fila === '' && lugar !== ''))) {
            openDialog('Se selecionar uma fila deve selecionar um lugar e vice-versa.', () => null)
            return;
        }
        if (estado === "recolhido" && !checkIsSpotAvailable()) {
            openDialog(`Lugar indisponível. Selecione outra fila-lugar.`, () => null)
            return;
        }
        // Check if delivery happend
        if (client?.stats === 'recolhido') {
            openDialog(`Este carro já foi recolhido.`, () => null)
            return;
        }
        // Check if video was submitted
        if (estado === "recolhido" && !video) {
            openDialog(`Tem de submeter o video para fazer a recolha.`, () => null)
            return;
        }
        const data = { ...client };
        const now = formatDate((new Date()).toISOString());
        data['carInfo'] = veiculo;
        data['park'] = parque;
        data['row'] = fila;
        data['spot'] = lugar;
        data['stats'] = estado;
        data['condutorRecolha'] = condutor;
        data['actionUser'] = context?.user.email || 'unknown';
        data['actionDate'] = now;
        data['action'] = estado === 'recolhido' ? "Recolha" : "Estado 'em recolha'";
        data['checkIn'] = now;
        data['carLocation'] = `https://www.google.com/maps/search/?api=1&query=${location?.latitude},${location?.longitude}`;
        // Email params
        const emailParams = {
            carInfo: veiculo,
            licensePlate: client?.licensePlate,
            checkIn: now,
            checkOut: client?.checkOut,
            email: client?.email,
            brand: client?.parkBrand,
        }
        try {
            updateClient(context, navigate, data, context?.user.id || 'unknown', estado === 'recolhido' ? "Recolha" : "Estado 'em recolha'", openDialog);
            // if (estado === "recolhido" && client?.email && client?.email !== 'não@tem.pt' && !client?.email.includes('não') && !client?.email.includes('teste'))
            //     emailjs.send('service_ccpv2ev', 'template_lmmi9l9', emailParams, 'jeStFGZ-400kFvT_-');
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }

    function changeParque(parqueName: string) {
        if (parqueName) {
            const park = parqueName.split(':')[1];
            const brand = parqueName.split(':')[0];
            setParque(parqueName);
            if (parkData[brand] && Object.keys(parkData[brand]).includes(park)) {
                setRows(parkData[brand][park].rows);
                setSpots(parkData[brand][park].spots);
            }
        } else {
            setParque('');
            setRows(null);
            setSpots(null);
        }
    }

    if (!client || !parkData) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <button onClick={() => navigate(-1)} className='absolute top-8 left-[5vw] quatro:left-[8vw] font-mybold'>⬅ VOLTAR</button>
            <div className='flex flex-col justify-center w-full gap-6 py-20 cinco:py-8'>
                <h1 className='text-[1.2rem] font-mybold text-[var(--primary)] text-center'>RECOLHA</h1>
                <Card
                    href={null}
                    client={client}
                    type={"recolhas"}
                />
                <div className='flex justify-center'>
                    <p>Reserva de: <span className='font-bold'>{client?.parkBrand}</span></p>
                </div>
                <div className='flex justify-between w-full gap-4'>
                    <div className='flex flex-col justify-between w-1/2'>
                        <p>Detalhes Carro:</p>
                        <textarea className="border-black border-2 rounded-[15px] p-2 resize-none h-full" value={veiculo} onChange={(e) => setVeiculo(e.target.value)} placeholder='| Veiculo' />
                    </div>
                    <div className='flex flex-col w-1/2 gap-4'>
                        <p>Recolher viatura:</p>
                        <button onClick={() => { window.open(process.env.REACT_APP_DRIVE_LINK, '_blank'); setVideo(true) }} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-white'>VIDEO</button>
                        <button onClick={() => navigate(`/cancelar/${id}`)} className='w-full text-[1rem] bg-[var(--yellow)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black'>CANCELAR</button>
                    </div>
                </div>
                <div className='flex justify-between gap-4'>
                    <div className='flex flex-col w-full gap-2'>
                        <div className='flex flex-col gap-2'>
                            <p>Nome:</p>
                            <input className="input bg-[rgba(.5,.5,.5,.1)]" disabled type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='| Name' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Data:</p>
                            <input className="input bg-[rgba(.5,.5,.5,.1)]" disabled type="text" value={data} onChange={(e) => setData(e.target.value)} placeholder='| Hora' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Parque:</p>
                            <select
                                name="parque"
                                value={parque}
                                className="select"
                                onChange={(e) => changeParque(e.target.value)}
                            >
                                <option value="">Selecionar</option>
                                {
                                    parksList && parksList.map((park: string, index: number) => {
                                        return (
                                            <option key={index}>{park}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Fila:</p>
                            <select
                                name="fila"
                                value={fila}
                                className="select"
                                onChange={(e) => setFila(e.target.value)}
                            >
                                <option value="">Selecionar</option>
                                {
                                    rows && rows.map((ele: number, index: number) => {
                                        return (
                                            <option key={index} value={ele}>{ele}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Lugar:</p>
                            <select
                                name="lugar"
                                value={lugar}
                                className="select"
                                onChange={(e) => setLugar(e.target.value)}
                            >
                                <option value="">Selecionar</option>
                                {
                                    spots && spots.map((ele: number, index: number) => {
                                        return (
                                            <option key={index} value={ele}>{ele}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <div className='flex flex-col gap-2'>
                            <p>Último nome:</p>
                            <input className="input bg-[rgba(.5,.5,.5,.1)]" disabled type="text" value={lastname} onChange={(e) => setLastname(e.target.value)} placeholder='| Lastname' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Hora:</p>
                            <input className="input bg-[rgba(.5,.5,.5,.1)]" disabled type="text" value={hora} onChange={(e) => setHora(e.target.value)} placeholder='| Hora' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Tipo de parque:</p>
                            <input className="input bg-[rgba(.5,.5,.5,.1)]" disabled type="text" value={parkingType} onChange={(e) => setParkingType(e.target.value)} placeholder='| Hora' />
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>Condutor:</p>
                            <input className="input" disabled={context?.user.type !== 'Admin'} type="text" value={condutor} onChange={(e) => setCondutor(e.target.value)} placeholder='| Condutor' />
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                    {client?.stats === 'reservado' && <button onClick={() => recolher("em recolha")} className='w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black'>EM RECOLHA</button>}
                    {(client?.stats === 'reservado' || client?.stats === 'em recolha') && <button onClick={() => recolher("recolhido")} className='w-full text-[1rem] bg-[var(--green)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black'>RECOLHER</button>}
                    <button onClick={ocorrencia} className='w-full text-[1rem] bg-[var(--orange)] border-[1px] hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black'>OCORRÊNCIA</button>
                    {context?.user?.type !== 'Condutor' && context?.user?.type !== 'Junior' && <button onClick={() => navigate(`/consulta/${client?.idClient}`)} className='w-full text-[1rem] bg-[var(--primary)] border-[1px] text-white hover:border-[1px] hover:border-black rounded-[12px] py-2 text-black'>CONSULTAR</button>}
                </div>
            </div>
            <Dialog
                message={dialogMessage}
                opened={dialogOpen}
                onDialog={closeDialog}
                onClose={dialogOnClose}
            />
        </>
    );
}

